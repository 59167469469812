import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from '../components/layout';

export default function NotFound() {
  return (
    <React.Fragment>
       <Layout>
      <h1>Page Not Found</h1>
      <p>Oops, we couldn't find this page!</p>
      </Layout>
    </React.Fragment>
  )
}